body, html {
  height: 100%;
  margin: 0;
  font-family: Yu Gothic UI;
  background-color: #F5F5F5;
  color: #292929;
}


.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../styling/images/Banner.png");
  background-position: center;
  background-size: contain;
  height: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.hero-banner {
  background-position: center;
  background-size: contain;
  height: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.aboutImage {
    margin-left: 2%;
    float: left; 
    margin-top: -16%; 
    width: 25%;
}

.aboutHeader{
    float: left; 
    margin-left: 2rem; 
    color: white; 
    margin-top: -6rem; 
    font-size: 5rem;
}

.aboutSubheading {
    float: left; 
    margin-left: -20rem; 
}

.hero-text button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px 25px;
  color: black;
  background-color: #ddd;
  text-align: center;
  cursor: pointer;
}

.hero-text button:hover {
  background-color: #555;
  color: white;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1%;
  margin-bottom: 2.4rem;
}

.logobox {
    width: 20%; 
    padding-top: 0.6rem;
}

.topnav {
  overflow: hidden;
  margin-top: 0rem;
}

.topnav a {
  text-align: center;
  display: inline-block;
  color: white;
  text-align: center;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
  font-family: Yu Gothic UI;
}

.topnav a:hover {
  color: aqua;
}

.topnav .icon {
  display: none;
  width: 10%;
  overflow: visible;
}

@media screen and (max-width: 600px) {
  .topnav a { 
    margin-left: 5%;
    text-align: center;}
  .topnav a:not(:first-child) {display: none; ;}
  .topnav a.icon {
    float: right;
    display: block;
  }
  .logobox {
    width: 40%; 
    padding-top: 1rem;
  }
    
  .aboutImage {
    margin-left: 1%;
    margin-top: -26%; 
    width: 45%;
  }
    
  .aboutHeader{
    color: white; 
    font-size: 3rem;
    margin-top: -4rem;
    padding: 0%;
    margin-bottom: 0%;
  }
    
  .aboutSubheading {   
    margin-left: 30px;
    font-size: 18px;
  }
    
    .contactbox {
     border: none;
    }
    
    .hero-image {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../styling/images/MobileBanner.png");
        background-position: center;
        background-size: contain;
          height: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
    }
}

.contactbox {
    margin-top: 8%; 
    margin-bottom: 8%;
    background-color: #e6e6e6; 
    padding-left: 10%; 
    padding-right: 10%; 
    padding-top: 5%; 
    padding-bottom: 5%; 
    border: solid;
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}