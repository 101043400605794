.topBanner {width: 100% ;
             height: 7rem;
                background-image: linear-gradient(
                  to bottom,
                  rgba(33, 33, 33, 1),rgba(20, 20, 20, 0));
                margin-top: 0;
}

.LogoBackground{
    width: 100%; 
    height: 100%; 
    float: left;
}

.backgroundContainer{
    background-color: white;
    height: 100%;
    padding-top: 5rem;
    float: none;
}

.par {font-size: 1.5rem}                